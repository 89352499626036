import { useRouter } from 'next/router'
import { PropsWithChildren, createContext, useContext, useEffect } from 'react'
import currentUserStore from '../../newPackages/StorageProviders/currentUserStore'
import { Logger } from '../../utils/logger'
import { mapPathnameToPageTitle } from '../analytics/AnalyticService'
import { getDaysBetweenDates, getTeamAccess, getUniversalParams, paymentMethodForPurchase } from './tealiumHelpers'
import {
  NewRestoreSubscriptionResponse,
  TealiumCancelSubscriptionParams,
  TealiumCheckoutParams,
  TealiumCreateAccountParams,
  TealiumDeleteAccountParams,
  TealiumFavoriteTeamParams,
  TealiumLoginMVPDParams,
  TealiumLoginParams,
  TealiumPurchaseErrorParams,
  TealiumPurchaseParams,
  TealiumPurchaseSGSParams,
  TealiumSelectPackageParams,
  TealiumService,
  TealiumUpdateNameParams,
  TealiumUpdatePaymentMethodParams,
  TealiumUpgradeToAnnualParams,
  TealiumViewPackagesParams,
  TrackParams,
  UtagDataArg,
} from './types'

export const TealiumServiceContext = createContext<TealiumService>({} as TealiumService)

type PropsForProvider = PropsWithChildren<{
  app: 'web' | 'connected-web'
}>

export const TealiumServiceProvider = ({ children, app }: PropsForProvider) => {
  const router = useRouter()
  const tealium = createTealiumService()

  useEffect(() => {
    const handlePageView = (app: 'web' | 'connected-web', pathname: string) => {
      // do nothing if window.utag.view is not defined
      if (typeof window?.utag?.view === 'undefined') {
        return
      }
      const data = {
        page_name: mapPathnameToPageTitle(app, pathname.split('?')[0]), // ensure no query string
        ...getUniversalParams(),
      }
      // log pageview
      window.utag.view(data)
    }

    handlePageView(app, router.asPath)

    const handleRouteChangeComplete = (pathname: string) => {
      handlePageView(app, pathname)
    }
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <TealiumServiceContext.Provider value={tealium}>{children}</TealiumServiceContext.Provider>
}

export const useTealium = () => {
  const tealium = useContext(TealiumServiceContext)
  return tealium
}

export const createTealiumService = (): TealiumService => {
  const sendAnalytics = <T,>(params: TrackParams<T>) => {
    if (typeof window?.utag === 'undefined') {
      return
    }

    const data: UtagDataArg<T> = {
      ...getUniversalParams(),
      ...params,
      tealium_event: params.event_name,
    }
    window.utag.link<T>(data)
  }

  // create TealiumService
  const tealiumService: TealiumService = {
    upsellOpen: () => {
      sendAnalytics({
        event_name: 'subscribe_modal_open',
      })
    },
    upsellClose: () => {
      sendAnalytics({
        event_name: 'subscribe_modal_close',
      })
    },
    upsellStartTrial: () => {
      sendAnalytics({ event_name: 'subscribe_modal-start_trial' })
    },
    upsellSignIn: () => {
      sendAnalytics({ event_name: 'subscribe_modal-sign_in' })
    },
    upsellSignInWithTVProvider: () => {
      sendAnalytics({ event_name: 'subscribe_modal-tv_provider' })
    },
    createAccount: (params) => {
      sendAnalytics<TealiumCreateAccountParams>({
        event_name: 'create_account',
        ...params,
      })
    },
    logIn: (params) => {
      sendAnalytics<TealiumLoginParams>({
        event_name: 'login',
        ...params,
      })
    },
    logInMVPD: (params) => {
      sendAnalytics<TealiumLoginMVPDParams>({
        event_name: 'mvpd_login',
        ...params,
      })
    },
    logOut: () => {
      sendAnalytics({
        event_name: 'log_out',
      })
    },
    changeEmail: () => {
      sendAnalytics({
        event_name: 'change_email',
      })
    },
    deleteAccount: (params) => {
      sendAnalytics<TealiumDeleteAccountParams>({
        event_name: 'delete_account',
        ...params,
      })
    },
    updateName: (params) => {
      sendAnalytics<TealiumUpdateNameParams>({
        event_name: 'update_name',
        ...params,
      })
    },
    removeFavoriteTeam: (params) => {
      sendAnalytics<TealiumFavoriteTeamParams>({
        event_name: 'remove_team_fav',
        ...params,
      })
    },
    addFavoriteTeam: (params) => {
      sendAnalytics<TealiumFavoriteTeamParams>({
        event_name: 'team_fav',
        ...params,
      })
    },
    cancelSubscription: (params) => {
      sendAnalytics<TealiumCancelSubscriptionParams>({
        event_name: 'cancel_subscription',
        ...params,
      })
    },
    restoreSubscription: (restoredSubscriptionResponse, subscription) => {
      const currentUser = currentUserStore.getItem()
      const res = restoredSubscriptionResponse as NewRestoreSubscriptionResponse
      const free_trial_start = res?.free_trial_start ?? subscription?.free_trial_start
      const free_trial_end = res?.free_trial_expiration ?? subscription?.free_trial_expiration
      sendAnalytics<TealiumPurchaseParams>({
        email: currentUser?.profile?.internal?.email ?? '',
        order_id: res.order_id,
        order_subtotal: res.retail_price,
        order_total: res.purchase_price,
        payment_method: paymentMethodForPurchase(res.payment_method),
        trial_duration: getDaysBetweenDates(free_trial_start, free_trial_end),
        package: subscription.service_name,
        package_code: subscription.service_id,
        package_id: res.service_id,
        package_duration: res.billing_interval,
        currency: 'USD',
        team_access: getTeamAccess(currentUser),
        entitlement: 'bally_active', // always bally_active for restore_purchase event
        event_name: 'restore_purchase',
      })
    },
    pauseSubscription: (params) => {
      sendAnalytics({
        event_name: 'pause_subscription',
        ...params,
      })
    },
    resumeSubscription: () => {
      sendAnalytics({
        event_name: 'resume_subscription',
        entitlement: 'bally_active', // always bally_active for resume_subscription event
      })
    },
    updatePaymentMethod: (params) => {
      sendAnalytics<TealiumUpdatePaymentMethodParams>({
        event_name: 'update_payment_method',
        ...params,
      })
    },
    viewPackages: (params) => {
      sendAnalytics<TealiumViewPackagesParams>({
        event_name: 'view_packages',
        ...params,
      })
    },
    selectPackage: (params) => {
      sendAnalytics<TealiumSelectPackageParams>({
        event_name: 'select_package',
        ...params,
      })
    },
    upgradeToAnnual: (params) => {
      sendAnalytics<TealiumUpgradeToAnnualParams>({
        event_name: 'upgrade_to_annual',
        ...params,
      })
    },
    checkout: (params) => {
      sendAnalytics<TealiumCheckoutParams>({
        event_name: 'checkout',
        ...params,
      })
    },
    purchase: ({ payment_method, new_subscription, user_profile, selectedPkg, isFreeTrial }) => {
      try {
        const free_trial_start = (new_subscription as any).free_trial_start
        const free_trial_expiration = (new_subscription as any).free_trial_expiration

        sendAnalytics<TealiumPurchaseParams>({
          event_name: 'purchase_success',

          email: user_profile.profile?.internal?.email ?? '',
          order_id: new_subscription?.order_id ?? '',
          order_subtotal: isFreeTrial ? 0 : selectedPkg.price,
          free_trial_start,
          free_trial_expiration,
          order_total: isFreeTrial ? 0 : new_subscription?.tax_details?.total_price_with_tax ?? 0,
          payment_method: paymentMethodForPurchase(payment_method),
          trial_duration: getDaysBetweenDates(free_trial_start, free_trial_expiration),
          package: selectedPkg.package_name,
          package_code: new_subscription.service_id,
          package_id: new_subscription.payment_id,
          package_duration: new_subscription.billing_interval,
          currency: 'USD',
          team_access: getTeamAccess(user_profile),
          entitlement: 'bally_active', // always bally_active for purchase event
        })
      } catch (error) {
        Logger.of('TealiumService.purchase').error('Error sending purchase success event to Tealium', error)
      }
    },
    purchaseSGS: (data) => {
      const { billedAmount, payment_method, vouchers, order_id, user_profile } = data
      try {
        sendAnalytics<TealiumPurchaseSGSParams>({
          event_name: 'purchase_success',
          order_id,
          order_total: billedAmount,
          order_subtotal: billedAmount,
          payment_method,
          trial_duration: 0,
          package: 'single game',
          package_id: 'fsd single game',
          package_duration: 'single game',
          currency: 'USD',
          email: user_profile.profile?.internal?.email ?? '',
          coupon_code: vouchers?.join(',') ?? '',
        })
      } catch (error) {
        Logger.of('TealiumService.purchaseSGS').error('Error sending purchase success event to Tealium', error)
      }
    },
    purchaseError: (params) => {
      sendAnalytics<TealiumPurchaseErrorParams>({
        ...params,
        event_name: 'purchase_error',
      })
    },
  }

  return tealiumService
}
